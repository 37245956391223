.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label{
  color:#12190F !important;
}


input[type=text], select {
  width: 100%;
  padding: 16px 20px;
  margin: 8px 0;
  display: inline-block;
  /* border: 1px solid #ccc; */
  border-radius: 6px;
  font-family: "Satoshi-Regular";
   
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
select{
  background: #fff;
}
select option {
  background: #fff;
  color: #000;
  padding: 16px;
  height: 50px;
}
select option:checked {
  background:  #E6F2F2;
  color: #000;
  ;
}

#projectdescription{
  width: 100%;
  height: 120px;
  margin-top: 16px;
  padding: 8px;
}


/**
* @license
*
* Font Family: Satoshi
* Designed by: Deni Anggara
* URL: https://www.fontshare.com/fonts/satoshi
* © 2024 Indian Type Foundry
*
* Satoshi Light 
* Satoshi LightItalic 
* Satoshi Regular 
* Satoshi Italic 
* Satoshi Medium 
* Satoshi MediumItalic 
* Satoshi Bold 
* Satoshi BoldItalic 
* Satoshi Black 
* Satoshi BlackItalic 
* Satoshi Variable (Variable font)
* Satoshi VariableItalic (Variable font)

*
*/
@font-face {
  font-family: 'Satoshi-Light';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Light.woff') format('woff'),
       url('./assets/Fonts/WEB//fonts/Satoshi-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-LightItalic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-LightItalic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-LightItalic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Regular';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Regular.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-Italic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Italic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Italic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Medium.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-MediumItalic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-MediumItalic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-MediumItalic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BoldItalic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-BoldItalic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-BoldItalic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: 'Satoshi-Black';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Black.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Black.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'Satoshi-BlackItalic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-BlackItalic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-BlackItalic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: 'Satoshi-Variable';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-Variable.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Variable.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-Variable.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 900.0;
*
* available axes:
'wght' (range from 300.0 to 900.0
*/
@font-face {
  font-family: 'Satoshi-VariableItalic';
  src: url('./assets/Fonts/WEB/fonts/Satoshi-VariableItalic.woff2') format('woff2'),
       url('./assets/Fonts/WEB/fonts/Satoshi-VariableItalic.woff') format('woff'),
       url('./assets/Fonts/WEB/fonts/Satoshi-VariableItalic.ttf') format('truetype');
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Manrope-Medium';
  src: url('./assets/Fonts/static/Manrope-Medium.ttf') format('truetype');
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-SemiBold';
  src: url('./assets/Fonts/static/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Bold';
  src: url('./assets/Fonts/static/Manrope-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}



